export default class Floor {

	  constructor(params) {
      	this.id = params.id;
        this.name = params.name;
        this.image = params.image_url;
        this.label = params.label;
        this.description = params.description;
        this.polygonData = params.polygon_data;
    }

    getPolygonData(){
      return this.polygonData;
    }
    

}

