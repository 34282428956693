export default class Image {


  static getImage(category,type){
    var path = "public/images/kiosk/small/";
    if(type && type == 'big'){
      path = "public/images/kiosk/big/";
    }

    if(DEBUG){
      return this.getImagePath(category,path);
    }else{
      return END_POINT + this.getImagePath(category,path);
    }
  }
  
  static getImagePath(category,path){
    switch(category) {
        case "Default":
          return path+'common.svg';
        

        case "Cafe":
          return path+'cafe.svg';
        

        case "Workstation":
          return path+'workstation.svg';
        

        case "Meeting":
          return path+'meeting.svg';
        

        case "Lab":
          return path+'lab.svg';
        

        case "Entrance":
          return path+'entrance.svg';
        

        case "Lift":
          return path+'lift.svg';
        

        case "Reception":
          return path+'reception.svg';
        

        case "Room":
          return path+'room.svg';
        
        case "Staircase":
          return path+'common.svg';

        case "Exit":
          return path+'exit.svg';
        
        case "Fire Exit":
            return path+'emergencyexit.svg'; //todo

        case "Restrooms":
            return path+'restrooms.svg';
        
        case "ATM":
            return path+'atm.svg';
        
        case "Baby Care Room":
            return path+'babycareroom.svg';
        
        case "Baggage Center":
            return path+'baggagecenter.svg';
        
        case "Drinking Water":
            return path+'drinkingwater.svg';
        
        case "Escalators":
            return path+'escalator.svg';
        
        case "Lost & Found":
            return path+'lostfound.svg';
        
        case "Pram Service":
            return path+'pramservice.svg';
    
        case "Restroom Differently Abled":
            return path+'Restroomfordifferentlyabled.svg';
        
        case "Customer Desk":
          return path+'customerdesk.svg';
      
        case "Wheelchair":
            return path+'wheelchair.svg';
    
        case "Community Center":
            return path+'communitycenter.svg';

        case "Auto Stand":
            return path+'autostand.svg';

        case "First Aid":
            return path+'firstaid.svg';

        case "Tailor":
            return path+'tailor.svg';

        case "Key Maker":
            return path+'keymaker.svg';
        
        default:
            return path+'common.svg';

    
    }
  		
  		
  }

}