import {Icon, Style, Text, Fill, Stroke} from 'ol/style.js';


export default class KioskPosition {

	constructor() {
    	 this.name = 'KioskPosition';
         this.marker;
  	}

  	toString() {
        return this.name;
    }

    style() {
        var src = END_POINT + 'public/images/whereiami.svg';
        if(DEBUG){
            src = 'public/images/direction-arrow.svg';
        }
    	return new Style({
            image: new Icon(({
                //color: '#df4a43',
                scale: 1,
                crossOrigin: null,
                src: 'public/images/whereiami.svg',
                anchor :[0.5,0.9],
                anchorOrigin:'top-left'
            })),
        });
    }


}
