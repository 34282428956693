
const BECO_DIR_LEFT = "left";
const BECO_DIR_RIGHT = "right";
const BECO_DIR_STRAIGHT = "straight";

export default class BecoMapMath {

    static det2x2(M) {
        return (M[0][0]*M[1][1])-(M[0][1]*M[1][0]);
     }

    static getDirection(x1, y1, x2, y2, x3, y3) {
        let matrix = [
            [(x1 - x3), (x2 - x3)],
            [(y1 - y3), (y2 - y3)]
        ];
        let dValue = this.det2x2(matrix);
        if (dValue == 0) {
            return BECO_DIR_STRAIGHT;
        } else {
            return (dValue < 0) ? BECO_DIR_RIGHT : BECO_DIR_LEFT;
        }
    }

    static getDistanceBetweenTwoPoints(x1, y1, x2, y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    static getIntermediatePointWith(start, end, maximumDistance) {
        let distanceBetweenNodes = this.getDistanceBetweenTwoPoints(start.x, start.y, end.x, end.y);
        let distanceRatio = maximumDistance/distanceBetweenNodes;
        if (distanceRatio > 0 && distanceRatio < 1) {
            return[((1-distanceRatio)*start.x)+(distanceRatio*end.x), ((1-distanceRatio)*start.y)+(distanceRatio*end.y)];
        } else {
            return[end.x,end.y];
        }
    }

    static getExtendPointWith(start, end, distance) {
        let lenAB = BecoMapMath.getDistanceBetweenTwoPoints(start.x, start.y, end.x, end.y);
        let x = end.x + (end.x - start.x) / lenAB * distance;
        let y = end.y + (end.y - start.y) / lenAB * distance;
        return  [x,y];
    }

    static getAngleBetweenTwoPoints(x1, y1, x2, y2, x3, y3) {
      var b = this.getDistanceBetweenTwoPoints(x1, y1, x2, y2);
      var a = this.getDistanceBetweenTwoPoints(x2, y2, x3, y3);
      var c = this.getDistanceBetweenTwoPoints(x1, y1, x3, y3);
      var cosAngle = ((Math.pow(b, 2) + Math.pow(a, 2)) - Math.pow(c, 2)) / (2 * a * b);
      cosAngle = Math.acos(cosAngle);
      //var angle = Math.toDegrees(Math.acos(cosAngle));
      var angle = cosAngle * (180/Math.PI);
      if (angle >= 88 && angle <= 92) {
          angle = 90;
      } else if (angle >= 170 && angle <= 191) {
          angle = 180;
      } else if (angle >= 268 && angle <= 272) {
          angle = 270;
      } else if (angle >= 358 && angle <= 2) {
          angle = 360;
      }
      return angle;
    }

    static getMidPointBetweenTwoPoints(x1, y1, x2, y2) {
      // Ref: http://www.movable-type.co.uk/scripts/latlong.html
      return  [x1 + (x2 - x1) * 0.5, y1 + (y2 - y1) * 0.5];
    }

    static getDegreeBetweenTwoPoints(x1, y1, x2, y2) {
        var radians = Math.atan2(y1 - y2, x1 - x2);
        return this.toDegrees(radians);
    }

    static getRadianBetweenTwoPoints(x1, y1, x2, y2) {
        return Math.atan2(y1 - y2, x1 - x2);
    }

    static toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    static slope(a, b) {
        if (a[0] == b[0]) {
            return null;
        }
        return (b[1] - a[1]) / (b[0] - a[0]);
    }

    static intercept(point, slope) {
        if (slope === null) {
            // vertical line
            return point[0];
        }

        return point[1] - slope * point[0];
    }

    static coordinates(A,B) {
        var m = this.slope(A, B);
        var b = this.intercept(A, m);
        console.log(m,b);
        var coordinates = [];
        for (var x = A[0]; x <= B[0]; x++) {
            var y = m * x + b;
            coordinates.push([x, y]);
        }
        return coordinates;
    }

    static getInitialRotationAngle(node1, node2, mapHeight) {

        // let y2 = mapHeight - 10
        // let exitAngle = getRadianBetweenTwoPoints(x1: n1.pointX,y1: n1.pointY, x2: n2.pointX,y2: n2.pointY)
        // let baseAngle = getRadianBetweenTwoPoints(x1: 0, y1: mapHeight, x2: 0, y2: y2)
        // let angleDifference = exitAngle - baseAngle
        // // Since open layer start with third Quadrant we need to add .PI
        // return angleDifference + .pi

        var y2 = mapHeight - 10;
        var y1 = parseFloat(mapHeight);
        var exitAngle = this.getRadianBetweenTwoPoints(node1.x,node1.y, node2.x,node2.y);
        var baseAngle = this.getRadianBetweenTwoPoints(0, y1, 0, y2);
        var angleDifference = exitAngle - baseAngle;
        return angleDifference + Math.PI; // Since openlayer start with third Qudarant
    }

    static radians(degrees) {
        return degrees * Math.PI / 180;
    }

    // Converts from radians to degrees.
    static degrees(radians) {
        return radians * 180 / Math.PI;
    }

    static calculateZoom(node1,node2) {

    }

}

