
import {Fill, Style, Text, Stroke} from 'ol/style.js';
import GeoJSON from 'ol/format/GeoJSON.js';

export default class Label {

	constructor() {
    	 this.name = 'Label';
	}

	toString() {
        return this.name;
    }

    getFeature(geojsonObject){
    	return (new GeoJSON()).readFeatures(geojsonObject);
    }

    style(feature) {
    	var font = 'normal '+feature.get('size')+'px "Open Sans", "Arial", "sans-serif"';
		return new Style({
	        text: new Text({
	          font: font,
	          placement: 'line',
	          fill: new Fill({
	            color: feature.get('textColor')
	          }),
	          text: feature.get('name'),
	          stroke: new Stroke({color: feature.get('borderColor'), width: 3}),
	          overflow: true,
	        })
	     });
    }

}