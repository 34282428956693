

export default class Feature{
    constructor(point,property,geometry){
        this.type = 'Feature';
        this.id = point;
        this.properties = property ;
        this.geometry = geometry;

    }
}
