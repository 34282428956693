import _ from 'underscore';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import {Icon, Style, Text, Fill, Stroke} from 'ol/style.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Select from 'ol/interaction/Select.js';
import { altShiftKeysOnly, singleclick } from 'ol/events/condition.js';


import Image from './images.js';

export default class Markers {

	constructor(map, markerObj, kioskMode) {
    	 this.name = 'Markers';
    	 this.defaultImage = "public/images/kiosk/small/common.svg";
    	 this.select;
		 this.map = map;
		 this.markerObj = markerObj;
		 this.kioskMode =  kioskMode;
		 this.features;
         this.select ;
		 this.selectedMarkers = [];
  	}

  	toString() {
        return this.name;
    }


    style() {

    	return new Style({
            text: new Text({
				offsetY: 15,
				font: 'bold 10px Open Sans,sans-serif',
				fill: new Fill({ color: '#F90F00' }),
				stroke: new Stroke({ color: '#eee', width: 0.5 }),
                overflow: true,
        	})
        });
    }

    getImage(category,type){
    	var key = category.replace(/\s+/g, '-').toLowerCase();
    	key = key.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'and');
    	if(this.markerObj.hasOwnProperty(key)){
    		var uri = this.markerObj[key]['basePath'];
    		if(type && type == 'big'){
    			uri += this.markerObj[key]['imageLarge'];
    		}else {
    			uri += this.markerObj[key]['imageSmall'];
    		}
    		return uri;
    	}else{
    		return this.defaultImage;
    	}
    }

    styleSelected(category, text, color, clickable) {
		if (clickable) {
			return new Style({
				image: new Icon(({
					scale: 1,
					crossOrigin: null,
					src: this.getImage(category,'big'),
					anchor :[0.55,0.9],
					anchorOrigin:'top-left'
				})),
				text: new Text({
					offsetY: 10,
					text: text,
					font: 'bold 10px Open Sans,sans-serif',
					fill: new Fill({ color: color }),
					stroke: new Stroke({ color: '#eee', width: 0.5 }),
					overflow: true
				}),
				zIndex : 2
			});
		} else {
			return new Style({
				text: new Text({
					offsetY: 15,
					font: 'bold 10px Open Sans,sans-serif',
					fill: new Fill({ color: '#F90F00' }),
					stroke: new Stroke({ color: '#eee', width: 0.5 }),
					overflow: true,
				})
			});
		}
    }
	
	getFeature(geojsonObject){
		var features = (new GeoJSON()).readFeatures(geojsonObject);
		this.features = geojsonObject ;
    	return features;
	}

	slectedFeatures(category){
		var _this = this;
		var features = (new GeoJSON()).readFeatures(_this.features);
		var selectedMarekers = [];
		_(features).each(function(feature){
			if(feature.values_.category_name.toLowerCase() == category.toLowerCase()){
				selectedMarekers.push(feature.getGeometry().getCoordinates());
				feature.setStyle (_this.styleSelected(feature.get('category_name'),feature.get('name'),feature.get('category_color_selected')));
			}
		});
		this.selectedMarkers = selectedMarekers;
    	return features;
	}
	
	getSelectFeature(layer){
    	var _this = this;
    	var condition = altShiftKeysOnly;
    	if(!this.kioskMode){
    		condition = singleclick;
    	}
    	if(!this.select){
    		this.select = new Select({
	            condition: condition,
	            layers:[layer],
	            hitTolerance : 10,
	            style: function(feature) {
					console.log(feature.get('clickable'));
	                return _this.styleSelected(feature.get('category_name'),feature.get('name'),feature.get('category_color_selected'),feature.get('clickable'));
	            }
        	});

        	this.select.on('select', function(evt){
                if(evt.selected.length > 0 && evt.selected[0].get('clickable')){
                	_this.map.OnPointClickListener(evt.selected[0].getId());
                }
            });
    	}
    	
        return this.select;
	}
	
	getSelectedMarkers(){
		return this.selectedMarkers;
	}

    clearSelectedPoint(){
        if(this.select){
            var features = this.select.getFeatures();
            features.clear();
            this.selectedMarkers  = [];
        }
    }

}
