
import BecoMapMath from '../math/mapmath';

const BECO_MAP_HEIGHT = 1280;

export default class BEStep {
	constructor(params) {
        this.name = "BEStep";
        this.currentNode = params.currentNode; ///The node which represents the BEStep
        this.nextNode = params.nextNode; ///The next  node in BEFloorRoute which represents the BEStep
        this.previousNode = params.previousNode; ///The previous  node in BEFloorRoute which represents the BEStep
        this.rotationAngle = 0; ///Angle in radian, Calculated WRT the current and next nodes  in the route path. Used for map rotation angle
        this.angleInDegrees = params.angleInDegrees || 0; ///Calculated WRT the next and previous points in the route path. Used for assistance message.
        this.stepMessage; ///The assistance message for the BEStep
        this.stepImageType; ///The assistance Image for the BEStep
        this.floorNodeIndex = params.floorNodeIndex; /// Represents the index of currentNode in the route path.
        this.stepIndex = params.stepIndex;
        this.arrowCords = null;/// arrowCords represent the list of coordinates for the direction arrow in each step.
        this.markedAsDestination = false;
        this.markedAsFloorSwitcher = false;//The step is used to switch floor
        this.stepDirection;
        this.init(params);
    }
    
    init(params) {
        if (this.nextNode != null) {
            this.rotationAngle = BecoMapMath.getInitialRotationAngle(this.currentNode,this.nextNode,BECO_MAP_HEIGHT);
        }
        if (this.nextNode == null && params.nextFloor == null) {
            this.markedAsDestination = true;
        } else if (this.nextNode == null && params.nextFloor != null) {
            this.markedAsFloorSwitcher = true;
        } else {
            this.arrowCords = this._getArrowCordinates(this.previousNode,this.currentNode,this.nextNode);
        }
        if (this.nextNode && this.previousNode) {
            this.stepDirection = BecoMapMath.getDirection(this.previousNode.x,this.previousNode.y,this.currentNode.x,this.currentNode.y,this.nextNode.x,this.nextNode.y);
        }
    }

  	toString() {
        return this.name;
    }

    getName() {
    	return this.name;
    }

    getFocusRange() {
        var _this = this;
        var focusRange = [];
        if (_this.previousNode) {
            focusRange.push(BecoMapMath.getIntermediatePointWith(_this.currentNode, _this.previousNode, 60));
        }
        focusRange.push([_this.currentNode.x,_this.currentNode.y]);
        if (_this.nextNode) {
            focusRange.push([_this.nextNode.x,_this.nextNode.y]);
        } 
        return focusRange;
    }

    _getArrowCordinates(previous,current,next) {
        if (previous != null || next != null) {
            var arrowCordinates = [];
            if (previous != null) {
                arrowCordinates.push(BecoMapMath.getIntermediatePointWith(current, previous, 15));
            }
            arrowCordinates.push([current.x,current.y]);
            if (next != null) {
                arrowCordinates.push(BecoMapMath.getIntermediatePointWith(current, next, 15));
            }
            //console.log("ArrowCordinates",arrowCordinates);
            return arrowCordinates;
        }
        return null;
    }
}