import _ from 'underscore';
import Map from 'ol/Map.js';
import {defaults as defaultControls, Attribution} from 'ol/control.js';
import {defaults as defaultInteractions} from 'ol/interaction.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import VectorSource from 'ol/source/Vector.js';
import { Icon, Fill } from 'ol/style.js';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import {easeIn, easeOut} from 'ol/easing.js';
import {getCenter} from 'ol/extent';
//import { easeIn, easeOut } from 'ol/easing';

import Floors from '../models/floor/model.js';
import API from '../network/network';
import Route from '../models/route/model.js';
import Feature from 'ol/Feature';
import Markers from '../models/marker/model.js';
import Area from '../models/area/model.js';
import KioskPosition from '../models/currentposition/model.js';
import DestinationPosition from '../models/destinationposition/model.js';
import Image from '../models/marker/images.js';
import Label from '../models/label/model.js';
import { view, extent }  from './projection.js';
import Arrow from '../models/route/arrow.js';


const BECO_ROUTE_COLOUR = "#0099EA";
const BECO_COVERED_ROUTE_COLOUR = "#CDCDCD";
export default class BeCoMap {

	constructor(floorData, params, target, graph){
        this.floors = new Floors(floorData);
        this.markers = new Markers(this, params.markerObj,params.kioskMode);
        this.area = new Area(this);
        this.kioskMode = params.kioskMode;
        this.kioskPosition = new KioskPosition();
        this.destinationPosition = new DestinationPosition();
        this.map = null;
        this.vectorMarkerLayer = null;
        this.vectorRouteLayer = null;
        this.vectorKioskPositionLayer = null;
        this.vectorLabelLayer = null;
        this.routeLayer;
        this.arrowLayer = null;
        this.target = target;
        this.OnPointClickListener = null;
        this.OnClickListener = null;
        this.OnRouteFinishedListner = null;
        this.graph = graph;
        this.didRoutePlotted = false;
        this.labels = new Label();
        if(params.color){
            this.color = params.color;
        }else {
            this.color = "#C1D1A3";
        }
        if(params.minZoom){
            this.minZoom = params.minZoom;
        }
        if(params.maxZoom){
            this.maxZoom = params.maxZoom;
        }
        if(params.defaultZoom){
            this.defaultZoom = params.defaultZoom;
        }
        ///Arrow attributes
        this.arrow = new Arrow(); ///styl generator for arrow layer
        
        /// MARK:- Route attributes.
        this.route;
        this.coveredRoute;
        this.routeType = (params.routeType) ? params.routeType : "default";
        this.routeColor = (params.routeColor) ? params.routeColor : BECO_ROUTE_COLOUR;
        this.coveredRouteColor = (params.coveredRouteColor) ? params.coveredRouteColor : BECO_COVERED_ROUTE_COLOUR;
    }

    // initialize plugin
    init() {
        document.getElementById(this.target).style.backgroundColor = this.color;

        var markerStyle = this.markers.style();
        var kioskPositionStyle = this.kioskPosition.style();
        var destinationPositionStyle = this.destinationPosition.style();
        var _this = this;

        this.vectorLabelLayer = new VectorLayer({
            title : "Labels",
            zIndex : 2,
            displayInLayerSwitcher: false,
            declutter: true,
            source: new VectorSource({
                features: []
            }),
            style: function(feature) {
                return _this.labels.style(feature);
            }
        });

        this.vectorMarkerLayer = new VectorLayer({
            title : "Markers",
            displayInLayerSwitcher: false,
            zIndex : 2,
            declutter : true,
            renderBuffer: 2800 ,// value in pixels
            source: new VectorSource({
                features: []
            }),
            style: function(feature, resolution) {
                markerStyle.setImage(new Icon(({
                        scale: 1,
                        src: _this.markers.getImage(feature.get('category_name')),
                        anchor :[0.5,0.5],
                        anchorOrigin:'top-left'
                    }))
                );                

                if(!_this.kioskMode){
                    markerStyle.getText().setFill(new Fill({ color: feature.get('category_color') }));
                    markerStyle.getText().setText(feature.get('name'));
                }
                
                // if(resolution < 2 && !_this.kioskMode){
                //     markerStyle.getText().setFill(new Fill({ color: feature.get('category_color') }));
                //     markerStyle.getText().setText(feature.get('name'));
                // }else{
                //     markerStyle.getText().setText("");
                // }
                
                return markerStyle;
            }
        });

        this.vectorKioskPositionLayer = new VectorLayer({
            title : "KioskPosition",
            displayInLayerSwitcher: false,
            renderBuffer: 2800 ,
            zIndex : 1.5,
            source: new VectorSource({
                features: []
            }),
            style: function(feature, resolution) {
                return kioskPositionStyle;
            }
        });

        this.vectorDestinationPositionLayer = new VectorLayer({
            title : "DestinationPosition",
            displayInLayerSwitcher: false,
            renderBuffer: 2800 ,
            zIndex : 2,
            source: new VectorSource({
                features: []
            }),
            style: function(feature, resolution) {
                return destinationPositionStyle;
            }
        });

        this.vectorPolygonLayer = new VectorLayer({
            title : "Polygon Layer",
            displayInLayerSwitcher: false,
            opacity : 0,
            source: new VectorSource({
                features: []
            }),
            style: function(f) {
                return _this.area.getStyle();
            }
        });


        this.arrowLayer = new VectorLayer({
            title : "ArrowLayer",
            displayInLayerSwitcher: false,
            zIndex : 2,
            declutter : true,
            source: new VectorSource({
                features: []
            }),
            style: function(feature, resolution) {
                return _this.arrow.style(feature);
            }
        });

        
        //Apply user defined configuration to view if exist
        if(this.defaultZoom){
            view.setZoom(this.defaultZoom);
        }
        if(this.maxZoom){
            view.setMaxZoom(this.maxZoom);
        }
        if(this.minZoom){
            view.setMinZoom(this.minZoom);
        }

    	this.map = new Map({
                layers : this.getLayers(),
		        target: this.target,
		        view: view,
                controls: this.showButtons(),
                interactions: defaultInteractions({altShiftDragRotate:false, pinchRotate:false, doubleClickZoom: false})
      	});


        this.floors.setInitialFloorTile();
        //console.log(this.routeColor,this.coveredRouteColor);
        this.route = new Route(this.map,this.routeColor, this.coveredRouteColor);
        
        this.vectorRouteLayer = new VectorLayer({
            title : "Route Layer",
            displayInLayerSwitcher: false,
            declutter: true,
            zIndex : 1,
            renderBuffer: 2800 ,
            updateWhileAnimating : true,
            source: new VectorSource({
                features: []
            }),
            style: function(feature, resolution) {
                if(_this.routeType == "dashed"){
                    return _this.route.style(feature, resolution);
                } else {
                    return _this.route.defaultStyle(feature);
                }
            }
        });
        this.map.addLayer(this.vectorRouteLayer);
        this.map.addInteraction(this.area.getSelectFeature(this.vectorPolygonLayer,this.graph));
        this.map.addInteraction(this.markers.getSelectFeature(this.vectorMarkerLayer));
        this.map.once('rendercomplete', function(event) {
            //console.log(event);
            //console.log("rendercomplete");
        });

        this.map.on('click', function(event) {
            if(_this.OnClickListener){
                _this.OnClickListener();
            }

        });
        
    }


    getLayers() {
        var tileSource = Object.assign([],this.floors.getTileSource());
        tileSource.push(
                    this.vectorMarkerLayer,
                    this.vectorKioskPositionLayer,
                    this.vectorPolygonLayer,
                    this.vectorDestinationPositionLayer,
                    this.arrowLayer,
                    this.vectorLabelLayer
        );

        return tileSource;
    }

    setZoom(zoomLevel){
        this.map.getView().setZoom(zoomLevel);
    }

    setRotation(rotationAngle){
        this.map.getView().setRotation(rotationAngle);
    }

    setCenter(){
        this.map.getView().setCenter(getCenter(extent));
    }

    addLabels(geojsonObject){
        this.vectorLabelLayer.getSource().clear();
        this.vectorLabelLayer.getSource().addFeatures(this.labels.getFeature(geojsonObject));
    }


    clearLabels(){
        this.vectorLabelLayer.getSource().clear();
    }

    showButtons() {
    	var control = defaultControls({
            rotate: false,
	        attributionOptions:  ({
                collapsible: false,
                
	        })
    	});
    	return control;
    }

    getMap() {
    	return this.map;
    }

    addMarkers(geojsonObject){
        this.vectorMarkerLayer.getSource().clear();
        this.vectorMarkerLayer.getSource().addFeatures(this.markers.getFeature(geojsonObject));
    }

    selectMarkers(category){
        this.vectorMarkerLayer.getSource().clear();
        this.vectorMarkerLayer.getSource().addFeatures(this.markers.slectedFeatures(category));
        var _this = this;
        setTimeout(function() {
            _this.tour(_this.markers.getSelectedMarkers());
        }, 1500);
        
    }

    clearMarkers(){
        this.vectorMarkerLayer.getSource().clear();
        this.markers.clearSelectedPoint();
    }


    addAreas(geojsonObject){
        this.vectorPolygonLayer.getSource().clear();
        if(geojsonObject){
            this.vectorPolygonLayer.getSource().addFeatures(this.area.getFeature(geojsonObject));
        }
        
    }

    addKioskPoint(kioskPoint){
        this.vectorKioskPositionLayer.getSource().clear();
        var feature = new Feature(
                      new Point(kioskPoint.coordinates)
                  );
        this.vectorKioskPositionLayer.getSource().addFeature(feature);
    }

    removeKioskPoint(){
        this.vectorKioskPositionLayer.getSource().clear();
    }

    addDestinationPoint(coordinates){
        this.vectorDestinationPositionLayer.getSource().clear();
        var feature = new Feature(
            new Point(coordinates)
        );
        this.vectorDestinationPositionLayer.getSource().addFeature(feature);
    }

    removeDestinationPoint(){
        this.vectorDestinationPositionLayer.getSource().clear();
    }

    showFloorTile(floorID){
        this.floors.showFloorTile(floorID);
    }

    addArrow(arrowCoordinates){
        if(this.arrowLayer) {
            this.arrowLayer.getSource().clear();
        }
        var arrow = new Feature({
            geometry: new LineString(arrowCoordinates)
        });
        this.arrowLayer.getSource().addFeature(arrow); 
    }

    addArrows(steps){
        if(this.arrowLayer) {
            this.arrowLayer.getSource().clear();
        }
        
        this.arrowLayer.getSource().addFeatures(this.arrow.getFeatures(steps)); 
    }

    drawRoute(routeCoordinatesArray,destinationMarker,adjustToRoute){   
        var _this = this;
        if(this.routeLayer){
            this.vectorRouteLayer.getSource().removeFeature(this.routeLayer);
        }

        this.routeLayer = new Feature({
            geometry: new LineString(routeCoordinatesArray)
        });
        
        this.vectorRouteLayer.getSource().addFeature(this.routeLayer);
        if(!_this.kioskMode && adjustToRoute){
            this.map.getView().fit(this.routeLayer.getGeometry().getExtent(), {
                size:_this.map.getSize(),
                duration: 1000,
                padding :[25,25,25,25],
                easing: easeOut
            });
        }
        if(destinationMarker){
            this.addDestinationPoint(routeCoordinatesArray[routeCoordinatesArray.length -1]);
        }
    }

    drawCoveredRoute(routeCoordinatesArray){
        if(this.coveredRoute){
            this.vectorRouteLayer.getSource().removeFeature(this.coveredRoute);
        }
        
        this.coveredRoute = new Feature({
            geometry: new LineString(routeCoordinatesArray),
            routeType : "coveredroute" 
        });
        this.vectorRouteLayer.getSource().addFeature(this.coveredRoute);
    }

    rotate(angle, coordinates){
        var _this = this;
        this.map.getView().animate({
            duration: 2000,
            rotation:angle,
            easing:easeOut
        });
        
        if (coordinates) {
            var visibleRoute = new Feature({
                geometry: new LineString(coordinates)
            });
            this.map.getView().fit(visibleRoute.getGeometry().getExtent(), {
                size:_this.map.getSize(),
                duration: 1000,
                padding :[150,150,150,150],
                easing: easeOut
            });
        }
    }

    clearRoute(){
        if(this.vectorRouteLayer) {
            this.vectorRouteLayer.getSource().clear();
            this.removeDestinationPoint();
            this.routeLayer = null;
            this.coveredRoute = null;
            if(this.arrowLayer) {
                this.arrowLayer.getSource().clear();
            }
        }
    }

    cancelNavigation(){
        this.didRoutePlotted = false;
    }

    moveToPoint(x, y){
        this.map.getView().animate({
            zoom: 4,
            center: [x, y],
            duration: 2000,
            easing:easeIn
        });
    }

    moveToPointWithZoom(x, y,zoom){
        this.map.getView().animate({
            zoom: zoom,
            center: [x, y],
            duration: 2000,
            easing:easeIn
        });
    }


    setOnPointClickListener(OnPointClickListener){
        this.OnPointClickListener = OnPointClickListener;
    }

    setOnClickListener(OnClickListener){
        this.OnClickListener = OnClickListener;
    }

    setOnRouteFinishedListner(OnRouteFinishedListner){
        this.OnRouteFinishedListner = OnRouteFinishedListner;
    }

    onPointSelected(id){
        if(this.OnPointClickListener){
            this.OnPointClickListener(id);
        }

    }

    getRoute(source,destination){

        var api = this.graph.getApi();
        var _this = this;
        api.getPath(source,destination,this.graph.getSiteId()).then(function(response){
            
            if(response.status == 200 && response.data.length > 2 ){
                _this.didRoutePlotted = true;
                var route = _this.graph.getRouteNodes(response.data);
                _this.OnRouteFinishedListner(route.response());
            }else {
                _this.didRoutePlotted = false;
                _this.OnRouteFinishedListner({
                    status : "FAILED",
                    sourceFloor : source,
                    destinationFloor : destination,
                });
                
            }
            
        });
    }

    onAreaSelected(source,target){
        if(this.didRoutePlotted){
            return;
        }
        var api = this.graph.getApi();
        var _this = this;
        api.getPath(source.id,target.id,this.graph.getSiteId()).then(function(response){
            
            if(response.data.length > 2 ){
                _this.didRoutePlotted = true;
                var route = _this.graph.getRouteNodes(response.data);
                _this.OnRouteFinishedListner(route.response());
            }
            
        });
       

    }

    locateOnMap(source,target,callback){
        if(this.didRoutePlotted){
            return;
        }
        var api = this.graph.getApi();
        var _this = this;
        api.getPath(source,target,this.graph.getSiteId()).then(function(response){
            if(response.data.length > 2 ){
                _this.didRoutePlotted = true;
                var route = _this.graph.getRouteNodes(response.data);
                if(route){
                    callback(route.response());
                }
            
            }
        });
    }

    flyTo(location, done) {
        var duration = 2000;
        var zoom = this.map.getView().getZoom();
        var parts = 2;
        var called = false;
        function callback(complete) {
            --parts;
            if (called) {
                return;
            }
            if (parts === 0 || !complete) {
                called = true;
                done(complete);
            }
        }
        this.map.getView().animate({
            center: location,
            zoom: zoom+1,
            duration: duration
        },{
            zoom: zoom,
            duration: duration
        }, callback);
        this.map.getView().animate({
            zoom: zoom,
            duration: duration
        }, callback);
    }
        
    tour(markers) {
        this.map.getView().cancelAnimations();
        var locations = markers ;
        var _this = this;
        var index = -1;
        function next(more) {
            if (more) {
                ++index;
                if (index < locations.length) {
                    var delay = index === 0 ? 0 : 750;
                    setTimeout(function() {
                        _this.flyTo(locations[index], next);
                    }, delay);
                } else {
                   
                }
            } else {
               
            }
        }
        next(true);
    }


}

