import Projection from 'ol/proj/Projection.js';
import '../../styles/attribution/attribution.scss';
import View from 'ol/View.js';
import {getCenter} from 'ol/extent.js';

var extent = [0, 0, 2800, 1280];
var imageSize = [2800, 1280];

var projection = new Projection({
	code: 'xkcd-image',
	units: 'pixels',
	extent: extent,
	axisOrientation:'ene'
});

var view = new View({
  	projection: projection,
  	center: getCenter(extent),
  	extent: extent,
  	zoom: 3,
  	minZoom: 3,
  	maxZoom: 4.5
});

var target = 'beco-container';

function attributionHtml() {
	return '';
}


export { view, projection, extent , attributionHtml, target, imageSize };

