import BecoMapMath from '../../math/mapmath.js';
import LineString from 'ol/geom/LineString';
import GeoJSON from 'ol/format/GeoJSON.js';
import Point from 'ol/geom/Point.js';
import { containsCoordinate } from 'ol/extent';
import { Icon, Style, Fill, Circle, Stroke} from 'ol/style.js';
import { calculatePointsDistance, calculateSplitPointCoords, calculateAngle } from './math.js';

export default class Route {

	constructor(map,routeColor,coveredRouteColor) {
    	this.name = 'Markers';
    	this.map = map;
    	this.routeColor = routeColor;
    	this.coveredRouteColor = coveredRouteColor;
	}

  	splitLineString(geometry, minSegmentLength, options) {
		var splitPoints = [];
		var coords = geometry.getCoordinates();

		var coordIndex = 0;
		var startPoint = coords[coordIndex];
		var nextPoint = coords[coordIndex + 1];
		var angle = calculateAngle(startPoint, nextPoint, options.alwaysUp);

		var n = Math.ceil(geometry.getLength()/minSegmentLength);
		var segmentLength = geometry.getLength() / n;
		var currentSegmentLength = options.midPoints ? segmentLength/2 : segmentLength;
		 
		for (var i = 0; i <= n; i++) {

		    var distanceBetweenPoints = calculatePointsDistance(startPoint, nextPoint);
		    currentSegmentLength += distanceBetweenPoints;

		    if (currentSegmentLength < segmentLength) {
		      coordIndex++;
		      if(coordIndex < coords.length - 1) {
		        startPoint = coords[coordIndex];
		        nextPoint = coords[coordIndex + 1];
		        angle = calculateAngle(startPoint, nextPoint, options.alwaysUp);
		        i--;
		        continue;
		      } else {
		        if (!options.midPoints) {
		          var splitPointCoords = nextPoint;
		          if (!options.extent || containsCoordinate(options.extent, splitPointCoords)) {
		            splitPointCoords.push(angle);
		            splitPoints.push(splitPointCoords);
		          }
		        }
		        break;
		      }
		    } else {
		      var distanceToSplitPoint = currentSegmentLength - segmentLength;
		      var splitPointCoords = calculateSplitPointCoords(startPoint, nextPoint, distanceBetweenPoints, distanceToSplitPoint);
		      startPoint = splitPointCoords.slice();
		      if (!options.extent || containsCoordinate(options.extent, splitPointCoords)) {
		        splitPointCoords.push(angle);
		        splitPoints.push(splitPointCoords);
		      }
		      currentSegmentLength = 0;
		    }
		}

		return splitPoints;
	}

	defaultStyle(feature){
		if(feature.getProperties() && feature.getProperties().routeType){
			return [
				new Style({
				  stroke: new Stroke({ color: this.coveredRouteColor,width: 6 }),
				  fill: new Fill({ color: '#40B3EF' }),
				}),
			];
		} else {
			return [
				new Style({
				  stroke: new Stroke({ color: this.routeColor,width: 6 }),
				  fill: new Fill({ color: '#40B3EF' }),
				}),
			];
		}
	}

	style(feature, resolution) {
		var size = 2;
		var style =[];
  		var mapSize = this.map.getSize();
  		var extent = this.map.getView().calculateExtent([mapSize[0] + (size*2), mapSize[1] + (size*2)]);
  		var src = END_POINT + 'public/images/direction-arrow.svg';
  		if(DEBUG){
  			src = 'public/images/direction-arrow.svg';
  		}
  		 
  		
  		var splitPoints = this.splitLineString(feature.getGeometry(), size * 2 * resolution, {alwaysUp: false, midPoints: false, extent: extent});
  		splitPoints.forEach( function(point,index) {

		    style.push(new Style({
		      geometry: new Point([point[0],point[1]]),
			      image: new Icon({
			        src: src,
			        rotateWithView: true,
			        scale: 0.5,
			        rotation: point[2]
			      })
		      	
		    }));

  		});
  
  		return style;
	}

}
