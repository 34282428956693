import '../styles/index.scss';

import BeCoMap from './map/map.js';
import Route from './models/route/model.js';
import Graph from './graph/graph';
import Beco from './app.js';
import _ from 'underscore';



//TODO https://medium.com/4thought-studios/documenting-javascript-projects-f72429da2eea
//https://gomakethings.com/whats-the-best-way-to-document-javascript/

var map;

/**
 * This will set the DEBUG config to the window Object. Used only for BeCo Internal Testing
*/
window.GLOBALS  = {
	DEBUG : DEBUG
};

/**
 * Initialise the map class. This method is called via javascript Bridge by Android/iOS
 * 
 * @param {Object} Floor Object
 * @param {params} Map properties
 */
window.initMap = function(apiKey,target,params,callback,error) {
	var graph = new Graph(apiKey,params.siteId,params.markersList);
	graph.init(function(floors){

		if (typeof myVar == 'undefined') {
			params.default_floor_id = floors[0].id;
		}
		
		_(floors).each(function(floor){
            
            if(floor.name == params.floorIndex){
            	params.default_floor_id = floor.id;
            }
        });
      	
		map = new BeCoMap(floors,params,target,graph);
		map.init();
		var app = new Beco(map,graph);
		callback(app,params.default_floor_id);
	},error);

};










