
import _ from 'underscore';
import { Style, Fill, Stroke, Icon} from 'ol/style.js';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';


export default class Arrow {

	constructor(){
		this.name = 'Arrow';
	}

	getFeatures(steps){
		var features = [];
		_(steps).each(function (step) {
			if(step.arrowCords){
				var arrow = new Feature({
            		geometry: new LineString(step.arrowCords)
        		});
        		features.push(arrow);
			}
			
		});
		return features;
	}

	style(feature){
		var coOrdinated = feature.getGeometry().getCoordinates();
		var end = coOrdinated[coOrdinated.length-1];
		var start = coOrdinated[coOrdinated.length-2];
		var dx, dy;
		dx = end[0] - start[0];
        dy = end[1] - start[1];
        var rotation = Math.atan2(dy, dx);
		return [
		  new Style({
		    stroke: new Stroke({
		      color: '#35393A',
		      width: 8
		    })
		  }),
		  new Style({
		    stroke: new Stroke({
		      color: '#FFFFFF',
		      width: 6
		    })
		  }),
		  new Style({
              geometry: new Point(end),
              image: new Icon({
                src: 'public/images/arrow2.png',
                anchor: [0.5, 0.55],
                rotateWithView: true,
                rotation: -rotation
              })
            })
		];
	}
}