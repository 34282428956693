
export default class Property{
    constructor(params){
        //this.name = params.name;
        this.name = params.name;
        this.icon = params.category.image_name;
        this.marker = this.icon+"-big";
        this.category_name = params.category.name;
        this.category_color = params.category.color;
        this.category_color_selected = params.category.text_color_selected;
        this.clickable = params.clickable;
    }
}
