

function calculatePointsDistance(coord1, coord2) {
	var dx = coord1[0] - coord2[0];
	var dy = coord1[1] - coord2[1];
	return Math.sqrt(dx * dx + dy * dy);
}

function calculateSplitPointCoords(startNode, nextNode, distanceBetweenNodes, distanceToSplitPoint) {
	var d = distanceToSplitPoint / distanceBetweenNodes;
	var x = nextNode[0] + (startNode[0] - nextNode[0]) * d;
	var y = nextNode[1] + (startNode[1] - nextNode[1]) * d;
	return [x, y];
}

function calculateAngle(startNode, nextNode, alwaysUp) {
	var x = (startNode[0] - nextNode[0]);
	var y = (startNode[1] - nextNode[1]);
	return alwaysUp ? Math.atan(x/y) : Math.atan2(x,y);
}

export { calculatePointsDistance, calculateSplitPointCoords, calculateAngle };