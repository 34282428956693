import { axiosInstance, amazonAxiosInstance } from "./axiosInstance.js";

export default class API {

	constructor(apiKey){
        this.name = "API";
        Object.assign(axiosInstance.defaults, {headers: {'BECO-USERTOKEN': apiKey}});
    }

    toString() {
        return this.name;
    }

    async getPath(source,target,siteid) {

        let res = await axiosInstance.get("api/v1/kiosk/route/",{
            params:{
                site_identifier:siteid,
                start:source,
                target:target
            }
        });
        return res;
    }

    async getBuidindData(siteIdentifier) {
        let res = await axiosInstance.get("api/v2/get-building-data/"+siteIdentifier+"/",{});
        return res;
    }

    async getSiteData(siteIdentifier) {
        let res = await axiosInstance.get("api/v2/get-map-data/"+siteIdentifier+"/",{});
        return res;
    }


    async getGraph(filename) {
        let res = await amazonAxiosInstance.get(filename,{
            responseType: 'arraybuffer',
        });
        return res.data;
    }


}