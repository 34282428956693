

export default class Category{
    constructor(params){
        this.name = params.name;
        this.identifier =params.identifier;
        this.color =params.color;
        this.text_color_selected =params.text_color_selected;
        this.image_name =params.image_name;

    }
}