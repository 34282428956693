import _ from 'underscore';

import {Style, Fill, Stroke} from 'ol/style.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import Select from 'ol/interaction/Select.js';
import { singleclick } from 'ol/events/condition.js';
import * as turf from '@turf/turf';


export default class Area{
    constructor(map){
        this.name = 'Area';
        this.map = map;
        this.select;
    }

    getStyle(){
        return new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)'
          })
        });
    }

    getFeature(geojsonObject){
        var features = (new GeoJSON()).readFeatures(geojsonObject);
    	return features;
    }

    getSelectFeature(layer,graph){
        var _this = this;
        if(!this.select){
            this.select = new Select({
                condition: singleclick,
                layers:[layer],
                style: function(feature, resolution) {
                    return null;
                }
                //hitTolerance : 10,
                
            });

            

            this.select.on('select', function(evt){
                if(evt.selected.length > 0){
                    var poly = turf.polygon(evt.selected[0].getGeometry().getCoordinates());
                    var points = turf.points(graph.getFloorNodes(graph.getCurrentFloor()));
                    const pointsWithin = turf.pointsWithinPolygon(points, poly);
                    if(pointsWithin.features && pointsWithin.features.length){
                        var destination = graph.getNode(pointsWithin.features[0].geometry.coordinates);
                        _this.map.onAreaSelected(graph.getCurrentKioskPoint(),destination);
                    }
                    
                    
                }
            });
        }
        
        return this.select;
    }

    clearSelectedPoint(){
        if(this.select){
            var features = this.select.getFeatures();
            features.clear();
        }
    }
}
