

export default class Point {

	  constructor(params,nodeId) {
      	this.identifier = params.identifier;
        this.locationId = params.locationId;
        this.name = params.name;
        this.nodeId = nodeId;
        this.categoryIdentifier = params.category.identifier;
        this.pointId = params.id;
        this.uuid = params.point_id;
        this.searchable = params.searchable;
	  }

  	toString() {
        return this.name;
    }

    getName(){
    	  return this.name;
    }

}