import _ from 'underscore';

export default class Building {

	  constructor(params) {
      	this.id = params.building_id;
        this.name = params.building_name;
        this.floorIds = [];
        var _this = this;
        _(params.floors).each(function(floor){
            _this.floorIds.push(floor.id);
        });
    }

}

