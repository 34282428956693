import _ from 'underscore';
import API from '../network/network';
import Protobuf from '../protobuf/protobuf';
import Point from './point.js';
import Floor from './floor.js';
import Category from './category';
import Feature from './feature';
import Property from './properties';
import Geometry from './geometry';
import Label from './label';
import GeoJsonObject from './geojson';
import Kiosk from './kiosk';
import Route from './route';
import Marker from './marker';
import Building from './building';

export default class Graph{

    constructor(apiKey,siteId,markersList){
        this.api = new API(apiKey);
        this.siteId = siteId;
        this.protoBuf = new Protobuf();
        this.nodeMap = new Map();
        this.nodeIdMap = new Map();
        this.routeMap = new Map();
        this.pointMap = new Map();
        this.categoryMap = new Map();
        this.geoJsonMap = new Map();
        this.labelGeoJsonMap = new Map();
        this.floorNodeMap = new Map();
        this.floorMap = new Map();
        this.floors = [];
        this.points = [];
        this.floorNodes = [];
        this.categories = [];
        this.features = [];
        this.labelFeatures = [];
        this.kioskPoints = [];
        this.floorCounter;
        this.floorDataCounter;
        this.markersList = markersList;
        this.currentKioskPoint;
        this.currentFloorId;
        this.markers = [];
        this.buildings = [];
    }


    getFloors(){
        return this.floors;
    }

    getBuildings(){
        return this.buildings;
    }

    getRouteNodes(nodes){
        var route = [];
        var _this = this;
        _(nodes).each(function (id) {
            route.push(_this.routeMap.get(id));
        });
        return new Route(route);
    }

    getSiteId(){
        return this.siteId;
    }

    getApi(){
        return this.api;
    }

    getPoints(){
        return this.points;
    }

    getFloorNodes(id){
        return this.floorNodeMap.get(id);
    }

    getCategories(){
        return this.categories;
    }

    getKioskPoints(){
        return this.kioskPoints;
    }

    getCurrentKioskPoint(){
        return this.currentKioskPoint;
    }

    setCurrentFloor(id){
        this.currentFloorId = id;
    }

    getCurrentFloor(){
        return this.currentFloorId;
    }

    setKioskPoint(kioskPoint){
        this.currentKioskPoint = kioskPoint;
    }

    getPoint(id){
        return this.pointMap.get(id);
    }

    getNode(coordinate){
        var id = this.getCoordinateIdentifier(coordinate[0], coordinate[1]);
        return this.nodeMap.get(id);
    }

    getNodeById(id){
        return this.nodeIdMap.get(id);
    }

    getMarkerFloors(category){
        var floors = [];
        _(this.markers).each(function(marker){
            if(marker.icon == category && !floors.includes(marker.floorId)){
                
                floors.push(marker.floorId);
            }
            
        });
        return floors;

    }

    getCoordinateIdentifier(x,y){
        return x>=y ? ((x * x)+x+y) :(x+(y * y));
    }

    getGeoJsonObject(id){
        return this.geoJsonMap.get(id);
    }

    getLabelObject(id){
        return this.labelGeoJsonMap.get(id);
    }
    
    getPolygonData(id){
        return this.floorMap.get(id).getPolygonData();
    }

    init(callback,errorCallback){
        let _this = this;
        this.floorCounter = 0;
        this.floorDataCounter = 0;
        this.api.getSiteData(_this.siteId).then(function (response) {

            if(response.status == 200 && response.data.length != 0){
                response.data = _.sortBy(response.data, 'name');
                _this.getBuildingData();
                _(response.data).each(function(floor){
                    var floorObject = new Floor(floor);
                    _this.floors.push(floorObject);
                    _this.floorMap.set(floor.id, floorObject);
                    _this.floorCounter += 1;
                    _this.getGraph(floor.build_name,floor.id,callback);
                });

            }else{
                errorCallback({
                    status : response.status,
                    detail : "site id return empty data"
                });
            }
            

        }).catch(function (error) {
           errorCallback({
                status : error.status,
                detail : error.data.detail
            });
        });
        
    }


    getBuildingData() {
        var _this = this;
        this.api.getBuidindData(_this.siteId).then(function (response) {

            if(response.status == 200 && response.data.length != 0){
                _(response.data).each(function(building){
                    var buildingObject = new Building(building);
                    _this.buildings.push(buildingObject);
                });
            } else {
                errorCallback({
                    status : response.status,
                    detail : "site id return empty data"
                });
            }
            

        }).catch(function (error) {
           console.log(error.stack);
        });
        
    }

    getGraph(filename,floorId,callback){
        var _this = this;
        this.api.getGraph(filename).then(function(value){
            _this.protoBuf.getGraph(value).then(function(value){
                _(value.nodes).each(function(node){
                    // Setting the floor ID to the Node & Points
                    node.locationId = floorId;
                    var id = _this.getCoordinateIdentifier(node.x, node.y);
                    _this.nodeMap.set(id,node);
                    // console.log(_this.nodeMap);
                    _this.routeMap.set(node.id,node);
                    
                    if(node.point){
                        _this.nodeIdMap.set( node.point.id,node);
                        node.point.locationId = floorId;
                        var geometry = new Geometry(node);
                        var point = new Point(node.point,node.id);
                        var property = new Property(node.point);
                        var category = new Category(node.point.category);
                        // Setting markers only for utitlites and services
                        if ((_this.markersList.includes(node.point.category.name) || _this.markersList.includes("*")) && node.point.visible){
                            var feature = new Feature(node.point.id,property,geometry);
                            _this.features.push(feature);
                            // setting markers array 
                            var marker = new Marker(node);
                            _this.markers.push(marker);   
                        } else {
                            _this.floorNodes.push([node.x,node.y]);                        
                        }
                        
                        if(!_this.categories.some(category_in => category_in.identifier === category.identifier)){
                            if(category.name.toLowerCase() != "kiosk"){
                                _this.categories.push(category);
                            }
            
                        }
                        if(node.point.category.name.toLowerCase() == "kiosk"){
                            var kioskPoint = new Kiosk(node);
                            _this.kioskPoints.push(kioskPoint);
            
                        }
                        _this.points.push(point);
                        _this.pointMap.set(node.point.id,point);
                        _this.categoryMap.set(node.point.category.identifier,category);
                    }
                    
                });
                _this.floorDataCounter += 1;
                var featureColection = new GeoJsonObject(_this.features);
                _this.floorNodeMap.set(floorId, _this.floorNodes);
                _this.features = [];
                _this.floorNodes = [];
                _this.geoJsonMap.set(floorId,featureColection);
                
                

                _(value.edges).each(function(edge){
                    
                    if(edge.label){
                        var start = _this.routeMap.get(edge.start);
                        var end = _this.routeMap.get(edge.end);
                        var geometry = new Label(start,end);
                        var properties = {
                            name:   edge.label,
                            textColor: edge.label_text_color,
                            borderColor: edge.label_border_color,
                            size: edge.label_text_size
                        };
                        
                        var feature = new Feature(edge.id,properties,geometry);
                        _this.labelFeatures.push(feature);
                    }
                    
                    
                });

                var featureColection = new GeoJsonObject(_this.labelFeatures);
                _this.labelGeoJsonMap.set(floorId,featureColection);

                if(_this.floorCounter == _this.floorDataCounter){
                    callback(_this.floors);
                }
            });
            
        });
    }

}