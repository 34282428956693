export default class BEFloorRoute {

  constructor(steps,floor_id,path,floorRouteIndex) {
    this.name = "BEFloorRoute";
    this.steps = steps; ///steps :- steps assicited with the path
    this.floor_id = floor_id;
    this.routePath = path; /// routePath is the list of nodes which represents the path
    this.floorRouteIndex = floorRouteIndex;/// The index value represents the position of this BEFloorRoute in your floorRoutes array.
  }

  toString() {
    return this.name;
  }

  getName(){
    return this.name;
  }

  getCompletePathCoordinates() {
		var _this = this;
    var nodeArrays = [];
    _this.routePath.forEach( function (node) {
      nodeArrays.push([node.x,node.y]);
    });
    return nodeArrays;
  }

}