

export default class Beco {

	constructor(map,graph){
		this.map = map;
		this.graph = graph;
		this.rotationAngle = null;
	}

	getFloors(){
		if(this.graph) return this.graph.getFloors();
	}

	getBuildings(){
		if(this.graph) return this.graph.getBuildings();
	}

	zoomIn(){
		var zoom = this.map.getMap().getView().getZoom();
		if(zoom){
			this.map.getMap().getView().setZoom(zoom + 0.25);
		}
	}

	zoomOut(){
		var zoom = this.map.getMap().getView().getZoom();
		if(zoom){
			this.map.getMap().getView().setZoom(zoom - 0.25);
		}
	}

	getPoints(){
		if(this.graph) return this.graph.getPoints();
	}
	
	getCategories(){
		if(this.graph) return this.graph.getCategories();
	}

	getRoute(source,destination){
		this.map.getRoute(source,destination);
	}

	getKioskPoints(){
		if(this.graph) return this.graph.getKioskPoints();
	}

	setCurrentFloor(id){
		if(this.graph) this.graph.setCurrentFloor(id);
	}

	setKioskPoint(kioskPoint){
		if(this.graph) this.graph.setKioskPoint(kioskPoint);
		this.map.addKioskPoint(kioskPoint);
	}

	setDestinationPoint(){
		this.map.addDestinationPoint();
	}

	getPoint(id){
		if(this.graph) return this.graph.getPoint(id);
	}

	setCustomZoom(zoomLevel){
		this.map.setZoom(zoomLevel);
	}

	setCenter(){
		this.map.setCenter();
	}

	getGeoJsonObject(id){
		if(this.graph) return this.graph.getGeoJsonObject(id);
	}

	getLabelObject(id){
		if(this.graph) return this.graph.getLabelObject(id);
	}

	getPolygonData(id){
		if(this.graph) return this.graph.getPolygonData(id);
	}

	moveToPoint(id){
		if(this.graph){
			var  point = this.graph.getNodeById(id);
			if (point) this.map.moveToPoint(point.x, point.y);
		}	
	}

	moveToPointWithZoom(id,zoom){
		if(this.graph){
			var  point = this.graph.getNodeById(id);
			if (point) this.map.moveToPointWithZoom(point.x, point.y,zoom);
		}	
	}

	getMarkerFloors(category){
		if(this.graph) return this.graph.getMarkerFloors(category);
	}

	locateOnMap(source,target,callback){
        if(this.graph){
            var targetNode = this.graph.getNodeById(target);
            // var sourceNode = this.graph.getNodeById(source);
            if(source && targetNode){
                this.map.locateOnMap(source,targetNode.id,callback);
            }
        }
    }

	switchFloor(id){
		this.map.clearRoute();
		this.map.clearMarkers();
		this.map.clearLabels();
		this.map.removeKioskPoint();
		this.map.showFloorTile(id);
		this.setCurrentFloor(id);
		this.addMarkers(id);
		this.addLabels(id);
		this.map.addAreas(this.getPolygonData(id));
		var currentKioskPoint = this.graph.getCurrentKioskPoint();
		if( currentKioskPoint && currentKioskPoint.locationId == id){
			this.map.addKioskPoint(currentKioskPoint);
		}
		if(this.rotationAngle){
			this.map.setRotation(this.rotationAngle);
		}
	}

	addMarkers(id){
		return this.map.addMarkers(this.getGeoJsonObject(id));
	}

	addLabels(id){
		return this.map.addLabels(this.getLabelObject(id));
	}

	selectMarkers(category){
			return this.map.selectMarkers(category);
	}
	

	addAreas(id){
		this.map.addAreas(this.getPolygonData(id));
	}


	setOnPointClickListener(OnPointClickListener){
		this.map.setOnPointClickListener(OnPointClickListener);
	}

	setOnClickListener(OnClickListener){
		this.map.setOnClickListener(OnClickListener);
	}

	setOnRouteFinishedListner(OnRouteFinishedListner){
		this.map.setOnRouteFinishedListner(OnRouteFinishedListner);
	}

	drawRoute(nodeList,destinationMarker,steps){
		this.map.clearRoute();
		if(nodeList) this.map.drawRoute(nodeList,destinationMarker,true);
		if(steps) this.map.addArrows(steps);
	}

	drawLiveRoute(floorRoute, step) {
		//console.log("drawLiveRoute");
		let routeCords = floorRoute.getCompletePathCoordinates();
		let coveredRoute = routeCords.slice(0,step.floorNodeIndex+1);
		let unCoveredRoute = routeCords.slice(step.floorNodeIndex);
		//console.log("Live Route Data",coveredRoute, unCoveredRoute, step.arrowCords);
		this.map.clearRoute();
		if(unCoveredRoute) this.map.drawRoute(unCoveredRoute,null,false);
		if(coveredRoute) this.map.drawCoveredRoute(coveredRoute);
		if(step.arrowCords) this.map.addArrow(step.arrowCords);
		this.map.rotate(step.rotationAngle,step.getFocusRange());
	}

	rotate(angle, focusrange) {
		this.map.rotate(angle, focusrange);
	}

	cancelNavigation() {
		this.map.clearRoute();
		this.map.cancelNavigation();
	}

	setRotateAngle(rotateAngle){
		this.rotationAngle = (Math.PI* rotateAngle )/180;
	}

}