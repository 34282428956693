import axios from "axios";



const axiosInstance = axios.create({
  baseURL: SERVICE_URL,
  timeout: 5000,
  withCredentials: true,
});


axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(error.response && error.response.status){
      if(error.response.status == 401){
          console.log("error in API key");
      }
    }
    return Promise.reject(error.response);
  }
);

const amazonAxiosInstance = axios.create({
    baseURL: AMAZON_SERVICE_URL,
    timeout: 5000,
});



export { axiosInstance ,amazonAxiosInstance};