import _ from 'underscore';

let pb = require('protobufjs');
var src = 'public/proto/graph.proto';

let builder = pb.loadProtoFile(src);


export default class Protobuf {

	constructor(){
        this.message = builder.build('graph');
        this.graph = this.message.Graph;
    }

    async getGraph(binaryData){        
        var graph = await this.graph.decode(binaryData);
        return graph;
    }


}

