
import _ from 'underscore';
import BecoMapMath from "../math/mapmath.js";
import BEStep from './step';
import BEFloorRoute from './floorRoute';

const BECO_MSG_FOLLOW = "Follow the path for some footsteps";
const BECO_MSG_REACHED = "You have reached your destination.";

export default class Route {

    constructor(routeObj){
		this.floorRouts;
		this.init(routeObj);
		console.log("Route path -> ",routeObj);
    }

    init(routeObj){
		var _this = this;
		_this._populateFloorRoutesAndSteps(routeObj);
		if (_this.floorRouts && _this.floorRouts.length == 0) {
			console.log("Failed to populate routes.");
			return;
		} 
	}
	
	_populateFloorRoutesAndSteps(actualPath) {
		var _this = this;
		let pathDistance = actualPath.length;
		if (pathDistance > 1) {
			let _previousFloorID;
			var _floorRouts = [];
			var currentFloor = null;
			var currentFloorPath = [];
			var stepList = [];
			_(actualPath).forEach( function (aNode, idn) {
				currentFloorPath.push(aNode);
				if (idn == pathDistance-1 || (idn < pathDistance-1 && actualPath[idn].locationId != actualPath[idn+1].locationId)) {//Floor/Route end
					let _previousNode = currentFloorPath.length > 1 ? currentFloorPath[currentFloorPath.length-2] : null;
					let _nextFloorID = idn == pathDistance-1 ? null : actualPath[idn+1].locationId;
					var aStep = new BEStep({currentNode: aNode, previousNode: _previousNode, nextFloor: _nextFloorID, floorNodeIndex: currentFloorPath.length-1, stepIndex: stepList.length});
					stepList.push(aStep);
					_this._populateStepInstructions(stepList,_nextFloorID,_previousFloorID);
					let _aFloorRoute = new BEFloorRoute(stepList,aNode.locationId,currentFloorPath,_floorRouts.length);
					_floorRouts.push(_aFloorRoute);
					currentFloor = null;
					currentFloorPath = [];
					stepList = [];
				} else {
					if (currentFloor == null) {//Floor route bigining
						_previousFloorID = idn == 0 ? null : actualPath[idn-1].locationId;
						currentFloor = aNode.locationId;
						var aStep = new BEStep({currentNode: aNode, nextNode: actualPath[idn+1], floorNodeIndex: currentFloorPath.length-1, stepIndex: stepList.length});
						stepList.push(aStep);
					} else {
						var _prevNd = actualPath[idn-1];
						var _nxtNd = actualPath[idn+1];
						let _angleInDegrees = BecoMapMath.getAngleBetweenTwoPoints(_prevNd.x,_prevNd.y,aNode.x,aNode.y,_nxtNd.x,_nxtNd.y);
						if (!(_angleInDegrees == 180 || isNaN(_angleInDegrees) || aNode.step)) {
							var aStep = new BEStep({currentNode: aNode, previousNode: _prevNd, nextNode:_nxtNd, angleInDegrees: _angleInDegrees, floorNodeIndex: currentFloorPath.length-1, stepIndex: stepList.length});
							stepList.push(aStep);
						}
					}
				}
			});
			_this.floorRouts = _floorRouts;
			//console.log("floorRouts",_this.floorRouts);
		}
	}

	//Function to populate step messages for steps in a single floor route
	_populateStepInstructions(floorSteps,nextFloorID,previousFloorID) {
		let stepDistance = floorSteps.length;
		_(floorSteps).forEach( function (aStep, idn) {
			var stepMessage = "";
			var stepImageType;
			let _nextStep = (idn == stepDistance-1) ? null : floorSteps[idn+1];
			switch (true) {
                case (_nextStep == null && nextFloorID == null):
					//You have reached your destination
					stepMessage = BECO_MSG_REACHED + ".";
					stepImageType = "BE_Destination_Reached";
				break;
				case (_nextStep == null && nextFloorID != null):
					//You have reached floor-end && step is floor exist
					let floorSwitcherName = aStep.currentNode.point == null ? "point" : aStep.currentNode.point.name;
					//TODO -: specify next floor name in step message. (Q: How to get floor name????)
					stepMessage = "Use the next " + floorSwitcherName + ", and take exit to next floor";
					stepImageType = "BE_Floor_Switcher";
				break;
				case (_nextStep != null && _nextStep.markedAsDestination):
					//You have reached second last step in the floor, and next is destination
					let destinationName = _nextStep.currentNode.point == null ? " point" : ", " + _nextStep.currentNode.point.name; 
					stepMessage = BECO_MSG_FOLLOW + " to reach the destination" + destinationName + ".";
					stepImageType = "BE_Straigt_Range";
				break;
				case (_nextStep != null && _nextStep.markedAsFloorSwitcher):
					//You have reached second last step in the floor, and next is floor switcher
					let floorSwitcherPoint = _nextStep.currentNode.point == null ? "the next point" : " " + _nextStep.currentNode.point.name; 
					stepMessage = BECO_MSG_FOLLOW + " to reach" + floorSwitcherPoint + ".";
					stepImageType = "BE_Straigt_Range";
				break;
				default:
					//Next step exist in same floor
					let x = _nextStep.angleInDegrees;
					let pointName = aStep.currentNode.point == null ? "start point" : aStep.currentNode.point.name; 
					if (idn == 0 && previousFloorID == null) {
						//Route bigining, Source point
						stepMessage = "Your are at " + pointName + ". ";
					} else if (idn == 0 && previousFloorID != null) {
						stepMessage = "Please take exit from " + pointName + ". ";
						//Floor route bigining, previous floor exist
					}  
					let direction = _nextStep.stepDirection;
						switch (true) {
							case (x >= 0 && x <= 9):///U Turn Range
								stepImageType = "BE_U_Turn_" + direction;
								stepMessage += BECO_MSG_FOLLOW + ", and take a U turn to the " + direction;
								break;
							case (x >= 10 && x <= 59):///Sharp Turn Range
								stepImageType = "BE_Sharp_Turn_" + direction;
								stepMessage += BECO_MSG_FOLLOW + ", and take a sharp  " + direction + " turn";
								break;
							case (x >= 60 && x <= 119):///Normal Turn Range
								stepImageType = "BE_Turn_" + direction;
								stepMessage += BECO_MSG_FOLLOW + ", and take a " + direction;
								break;
							case (x >= 120 && x <= 169):///Slight Turn Range
								stepImageType = "BE_Slight_Turn_" + direction;
								stepMessage += BECO_MSG_FOLLOW + ", and take a slight " + direction;
								break;
							case (x >= 170 && x <= 190):///Straigt Range
								stepImageType = "BE_Straigt_Range";
								stepMessage += BECO_MSG_FOLLOW + ".";
								break;
							default:
								stepImageType = "BE_Straigt_Range";
								stepMessage += BECO_MSG_FOLLOW + ".";
								break;
							}
				break;
			}
			console.log(stepMessage);
			//console.log(stepImageType);
			aStep.stepMessage = stepMessage;
			aStep.stepImageType = stepImageType;
		});
	}

	/// MARK:- Getters
	getRouteData() {
		var _this = this;
		return _this.floorRouts;
	}

	response() {
		return this;
	}

	getStepAfter(floorRouteIndex, stepIndex) {
		var _this = this;
		let currentFloorRoute = _this.floorRouts[floorRouteIndex];
		if (currentFloorRoute.steps.length-1 > stepIndex) {
			return {floorRoute: currentFloorRoute, step: currentFloorRoute.steps[stepIndex+1]};
		} else if(_this.floorRouts.length-1 > floorRouteIndex) {
			return {floorRoute: _this.floorRouts[floorRouteIndex+1], step: _this.floorRouts[floorRouteIndex+1].steps[0]};
		} else {
			return null;
		}
	}

	getStepBefore(floorRouteIndex, stepIndex) {
		var _this = this;
		let currentFloorRoute = _this.floorRouts[floorRouteIndex];
		if (stepIndex > 0) {
			return {floorRoute: currentFloorRoute, step: currentFloorRoute.steps[stepIndex-1]};
		} else if(stepIndex == 0 && floorRouteIndex > 0) {
			let newFloorRoute = _this.floorRouts[floorRouteIndex-1];
			return {floorRoute: newFloorRoute, step: newFloorRoute.steps[newFloorRoute.steps.length-1]};
		} else {
			return null;
		}
	}
}