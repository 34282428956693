import _ from 'underscore';
import ImageLayer from 'ol/layer/Image.js';
import Static from 'ol/source/ImageStatic.js';
import { view, projection, extent, attributionHtml, target, imageSize }  from '../../map/projection.js';

export default class Floors {

	  constructor(floors) {
    	 this.name = 'Floors';
    	 this.floors = floors;
    	 this.tileSources = new Map();
       this.tileArray = [];
       this.currentFloorId = this.floors[0].id;
    	 this.init();
  	}

  	toString() {
        return this.name;
    }

    getCurrentFloorID(){
        return this.currentFloorId ;
    }

  	init() {
    		var _this = this;
    		if (_this.floors) {
    			_(_this.floors).each(function (floor) {
  	            var tileSource = new ImageLayer({
                        title: floor.label,
                        displayInLayerSwitcher: true,
                        tileId : floor.id,
  	                    source: new Static({
  	                        attributions: attributionHtml(),
  	                        url: floor.image,
  	                        imageSize: imageSize,
  	                        projection: projection,
  	                        imageExtent: extent,
  	                    })
  	                });
                _this.tileArray.push(tileSource);
  	            _this.tileSources.set(floor.id, tileSource);
          	});
    		}
  	}


    /**
     * Get the tilesource array of the current Map.
     * @return {Array<import ImageLayer from 'ol/layer/Image.js'>} List of ImageLayers of the map.
     */
    getTileSource(){
        return this.tileArray.reverse();
    }

  	getFloorTile(floorID){
  		  return this.tileSources.get(floorID);
  	}

    showFloorTile(floorID){
      if(this.currentFloorId == floorID){
        return;
      }
      _(this.tileArray).each(function (layer) {
            if(layer.getProperties().tileId == floorID){
                layer.setVisible(true);
            }else{
                layer.setVisible(false);
            }
      });
      this.currentFloorId = floorID;

    }

    setInitialFloorTile(){
      if(!this.currentFloorId){
        return;
      }
      let currentFloorId = this.currentFloorId;
      _(this.tileArray).each(function (layer) {
            
            if(layer.getProperties().tileId == currentFloorId){
                layer.setVisible(true);
            }else{
                layer.setVisible(false);
            }
      });

    }

}